<div class="container">
  <h2>パスワードの再設定</h2>
  <mat-card>
    <mat-card-content>
      <form [formGroup]="resetPasswordForm">
        <mat-form-field appearance="outline">
          <mat-label>メールアドレス</mat-label>
          <input
            matInput
            id="email"
            autocomplete="email"
            type="email"
            placeholder="メールアドレスを入力してください"
            autocomplete="email"
            formControlName="email"
            required
          />
        </mat-form-field>
        <div class="button-container">
          <button
            mat-raised-button
            color="primary"
            [disabled]="resetPasswordForm.invalid"
            (click)="sendPasswordResetEmailButtonClick()"
            (keydown)="sendPasswordResetEmailButtonKeyDown($event)"
          >
            手順を送信する
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
