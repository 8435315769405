import { AsyncPipe } from '@angular/common'
import { Component } from '@angular/core'
import { MatIconButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu'
import { Router } from '@angular/router'

import { AuthService } from '../../services/auth.service'
import { AvatarComponent } from '../avatar/avatar.component'

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    AsyncPipe,
    AvatarComponent,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  user$ = this.authService.user$

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  logout = () => {
    console.log('Logged out')
    return this.authService.logout().finally(() => {
      this.router.navigate(['login'])
    })
  }
}
