import { Component } from '@angular/core'
import {
  ReactiveFormsModule,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { MatButton } from '@angular/material/button'
import { MatCard, MatCardContent } from '@angular/material/card'
import { MatFormField, MatLabel, MatInput } from '@angular/material/input'
import { Router } from '@angular/router'

import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'app-forgot-password-page',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatCard,
    MatCardContent,
    MatFormField,
    MatLabel,
    MatInput,
    MatButton,
  ],
  templateUrl: './forgot-password-page.component.html',
  styleUrl: './forgot-password-page.component.scss',
})
export class ForgotPasswordPageComponent {
  email = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required],
  })

  resetPasswordForm = new FormGroup({
    email: this.email,
  })

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  sendPasswordResetEmailButtonClick = () => {
    this.sendPasswordResetEmail()
  }

  sendPasswordResetEmailButtonKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.sendPasswordResetEmail()
    }
  }

  sendPasswordResetEmail = () => {
    return this.authService
      .sendPasswordResetEmail(this.email.value)
      .then(() => {
        alert(
          '指定されたメールアドレスにパスワード再設定用のメールを送信しました。メールに記載されたリンクより新しいパスワードを設定した後、再度ログインを行なってください。',
        )
        this.router.navigate(['home'])
      })
  }
}
