import { NgFor } from '@angular/common'
import { Component, OnInit } from '@angular/core'

import { GetUsersGQL, User } from '../../../_generated/graphql'
@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [NgFor],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss',
})
export class HomePageComponent implements OnInit {
  users: User[] = []

  constructor(private getUsersGQL: GetUsersGQL) {}

  ngOnInit(): void {
    this.getUsersGQL.fetch().subscribe(({ data }) => {
      this.users = data.getUsers
    })
  }
}
