export const environment = {
  graphqlUrl: 'http://localhost:4000',
  firebase: {
    projectId: 'roi-prototype-419706',
    appId: '1:875023316814:web:fa57bc838872309501101f',
    storageBucket: 'roi-prototype-419706.appspot.com',
    apiKey: 'AIzaSyAdjI1YDNz6x5p-_SY3h0z31rkCJUb5NVU',
    authDomain: 'roi-prototype-419706.firebaseapp.com',
    messagingSenderId: '875023316814',
  },
}
