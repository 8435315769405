<div class="container">
  <h2>ログイン</h2>
  <mat-card>
    <mat-card-content>
      <form [formGroup]="loginForm">
        <mat-form-field appearance="outline">
          <mat-label>メールアドレス</mat-label>
          <input
            matInput
            id="email"
            placeholder="メールアドレスを入力してください"
            autocomplete="email"
            formControlName="email"
            required
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>パスワード</mat-label>
          <input
            matInput
            id="password"
            type="password"
            placeholder="パスワードを入力してください"
            autocomplete="current-password"
            formControlName="password"
            required
          />
        </mat-form-field>
        <div class="button-container">
          <button
            mat-raised-button
            color="primary"
            [disabled]="loginForm.invalid"
            (click)="loginWithEmailAndPasswordButtonClick()"
            (keydown)="loginWithEmailAndPasswordButtonKeyDown($event)"
          >
            ログイン
          </button>
          <button
            mat-raised-button
            color="accent"
            (click)="loginWithGoogle()"
            (keydown)="loginWithEmailAndPasswordButtonKeyDown($event)"
          >
            Googleでログイン
          </button>
        </div>
      </form>
      <div class="forgot-password-container">
        <a [routerLink]="['/forgot-password']" mat-button color="primary"
          >パスワードを忘れた場合</a
        >
      </div>
    </mat-card-content>
  </mat-card>
</div>
