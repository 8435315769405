<div class="header">
  <app-avatar
    #avatar
    class="avatar"
    [photoUrl]="(this.user$ | async)?.photoUrl ?? ''"
  ></app-avatar>
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="this.logout()">ログアウト</button>
  </mat-menu>
</div>
