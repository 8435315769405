import { Component } from '@angular/core'
import {
  ReactiveFormsModule,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCard, MatCardContent } from '@angular/material/card'
import { MatFormField, MatLabel, MatInput } from '@angular/material/input'
import { Router, RouterLink } from '@angular/router'

import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatCard,
    MatCardContent,
    MatFormField,
    MatLabel,
    MatInput,
    MatButtonModule,
    RouterLink,
  ],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
})
export class LoginPageComponent {
  email = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required],
  })
  password = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required],
  })

  loginForm = new FormGroup({
    email: this.email,
    password: this.password,
  })

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  loginWithGoogleButtonClick = () => {
    this.loginWithGoogle()
  }

  loginWithGoogleButtonKeyDown = () => {
    this.loginWithGoogle()
  }

  loginWithGoogle() {
    return this.authService.loginWithGoogle().then(() => {
      this.router.navigate(['home'])
    })
  }

  loginWithEmailAndPasswordButtonClick = () => {
    this.loginWithEmailAndPassword()
  }

  loginWithEmailAndPasswordButtonKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.loginWithEmailAndPassword()
    }
  }

  loginWithEmailAndPassword = () => {
    return this.authService
      .loginWithEmailAndPassword(this.email.value, this.password.value)
      .then(
        () => {
          this.router.navigate(['home'])
        },
        (error) => {
          let errorMessage = ''
          if (error.code === 'auth/invalid-login-credentials') {
            errorMessage = 'メールアドレスまたはパスワードが間違っています。'
          } else if (error.code === 'auth/too-many-requests') {
            errorMessage =
              'このアカウントへのアクセスは、多くのログイン試行が失敗したため、一時的に無効化されています。すぐにアカウントを復元するには、パスワードをリセットするか、後で再試行してください。'
          } else {
            errorMessage = `${error.code}: ${error.message}`
          }
          alert(errorMessage)
        },
      )
  }
}
