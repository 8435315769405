import { Routes } from '@angular/router'

import { authGuard } from './guards/auth.guard'
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component'
import { HomePageComponent } from './pages/home-page/home-page.component'
import { LoginPageComponent } from './pages/login-page/login-page.component'
import { MainPageComponent } from './pages/main-page/main-page.component'

export const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordPageComponent,
  },
  {
    path: '',
    component: MainPageComponent,
    canActivate: [authGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        component: HomePageComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'main',
  },
]
