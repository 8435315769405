import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, NgModule } from '@angular/core';
import { Observable, queueScheduler, observable, from } from 'rxjs';
import { NetworkStatus, ApolloClient, gql as gql$1 } from '@apollo/client/core';
import { map, startWith, observeOn } from 'rxjs/operators';
function fromPromise(promiseFn) {
  return new Observable(subscriber => {
    promiseFn().then(result => {
      if (!subscriber.closed) {
        subscriber.next(result);
        subscriber.complete();
      }
    }, error => {
      if (!subscriber.closed) {
        subscriber.error(error);
      }
    });
    return () => subscriber.unsubscribe();
  });
}
function useMutationLoading(source, enabled) {
  if (!enabled) {
    return source.pipe(map(result => ({
      ...result,
      loading: false
    })));
  }
  return source.pipe(startWith({
    loading: true
  }), map(result => ({
    ...result,
    loading: !!result.loading
  })));
}
class ZoneScheduler {
  zone;
  constructor(zone) {
    this.zone = zone;
  }
  now = Date.now ? Date.now : () => +new Date();
  schedule(work, delay = 0, state) {
    return this.zone.run(() => queueScheduler.schedule(work, delay, state));
  }
}
function fixObservable(obs) {
  obs[observable] = () => obs;
  return obs;
}
function wrapWithZone(obs, ngZone) {
  return obs.pipe(observeOn(new ZoneScheduler(ngZone)));
}
function pickFlag(flags, flag, defaultValue) {
  return flags && typeof flags[flag] !== 'undefined' ? flags[flag] : defaultValue;
}
function useInitialLoading(obsQuery) {
  return function useInitialLoadingOperator(source) {
    return new Observable(function useInitialLoadingSubscription(subscriber) {
      const currentResult = obsQuery.getCurrentResult();
      const {
        loading,
        errors,
        error,
        partial,
        data
      } = currentResult;
      const {
        partialRefetch,
        fetchPolicy
      } = obsQuery.options;
      const hasError = errors || error;
      if (partialRefetch && partial && (!data || Object.keys(data).length === 0) && fetchPolicy !== 'cache-only' && !loading && !hasError) {
        subscriber.next({
          ...currentResult,
          loading: true,
          networkStatus: NetworkStatus.loading
        });
      }
      return source.subscribe(subscriber);
    });
  };
}
class QueryRef {
  obsQuery;
  valueChanges;
  queryId;
  constructor(obsQuery, ngZone, options) {
    this.obsQuery = obsQuery;
    const wrapped = wrapWithZone(from(fixObservable(this.obsQuery)), ngZone);
    this.valueChanges = options.useInitialLoading ? wrapped.pipe(useInitialLoading(this.obsQuery)) : wrapped;
    this.queryId = this.obsQuery.queryId;
  }
  // ObservableQuery's methods
  get options() {
    return this.obsQuery.options;
  }
  get variables() {
    return this.obsQuery.variables;
  }
  result() {
    return this.obsQuery.result();
  }
  getCurrentResult() {
    return this.obsQuery.getCurrentResult();
  }
  getLastResult() {
    return this.obsQuery.getLastResult();
  }
  getLastError() {
    return this.obsQuery.getLastError();
  }
  resetLastResults() {
    return this.obsQuery.resetLastResults();
  }
  refetch(variables) {
    return this.obsQuery.refetch(variables);
  }
  fetchMore(fetchMoreOptions) {
    return this.obsQuery.fetchMore(fetchMoreOptions);
  }
  subscribeToMore(options) {
    // XXX: there's a bug in apollo-client typings
    // it should not inherit types from ObservableQuery
    return this.obsQuery.subscribeToMore(options);
  }
  updateQuery(mapFn) {
    return this.obsQuery.updateQuery(mapFn);
  }
  stopPolling() {
    return this.obsQuery.stopPolling();
  }
  startPolling(pollInterval) {
    return this.obsQuery.startPolling(pollInterval);
  }
  setOptions(opts) {
    return this.obsQuery.setOptions(opts);
  }
  setVariables(variables) {
    return this.obsQuery.setVariables(variables);
  }
}
const APOLLO_FLAGS = new InjectionToken('APOLLO_FLAGS');
const APOLLO_OPTIONS = new InjectionToken('APOLLO_OPTIONS');
const APOLLO_NAMED_OPTIONS = new InjectionToken('APOLLO_NAMED_OPTIONS');
class ApolloBase {
  ngZone;
  flags;
  _client;
  useInitialLoading;
  useMutationLoading;
  constructor(ngZone, flags, _client) {
    this.ngZone = ngZone;
    this.flags = flags;
    this._client = _client;
    this.useInitialLoading = pickFlag(flags, 'useInitialLoading', false);
    this.useMutationLoading = pickFlag(flags, 'useMutationLoading', false);
  }
  watchQuery(options) {
    return new QueryRef(this.ensureClient().watchQuery({
      ...options
    }), this.ngZone, {
      useInitialLoading: this.useInitialLoading,
      ...options
    });
  }
  query(options) {
    return fromPromise(() => this.ensureClient().query({
      ...options
    }));
  }
  mutate(options) {
    return useMutationLoading(fromPromise(() => this.ensureClient().mutate({
      ...options
    })), options.useMutationLoading ?? this.useMutationLoading);
  }
  subscribe(options, extra) {
    const obs = from(fixObservable(this.ensureClient().subscribe({
      ...options
    })));
    return extra && extra.useZone !== true ? obs : wrapWithZone(obs, this.ngZone);
  }
  /**
   * Get an instance of ApolloClient
   * @deprecated use `apollo.client` instead
   */
  getClient() {
    return this.client;
  }
  /**
   * Set a new instance of ApolloClient
   * Remember to clean up the store before setting a new client.
   * @deprecated use `apollo.client = client` instead
   *
   * @param client ApolloClient instance
   */
  setClient(client) {
    this.client = client;
  }
  /**
   * Get an instance of ApolloClient
   */
  get client() {
    return this._client;
  }
  /**
   * Set a new instance of ApolloClient
   * Remember to clean up the store before setting a new client.
   *
   * @param client ApolloClient instance
   */
  set client(client) {
    if (this._client) {
      throw new Error('Client has been already defined');
    }
    this._client = client;
  }
  ensureClient() {
    this.checkInstance();
    return this._client;
  }
  checkInstance() {
    if (!this._client) {
      throw new Error('Client has not been defined yet');
    }
  }
}
class Apollo extends ApolloBase {
  _ngZone;
  map = new Map();
  constructor(_ngZone, apolloOptions, apolloNamedOptions, flags) {
    super(_ngZone, flags);
    this._ngZone = _ngZone;
    if (apolloOptions) {
      this.createDefault(apolloOptions);
    }
    if (apolloNamedOptions && typeof apolloNamedOptions === 'object') {
      for (let name in apolloNamedOptions) {
        if (apolloNamedOptions.hasOwnProperty(name)) {
          const options = apolloNamedOptions[name];
          this.create(options, name);
        }
      }
    }
  }
  /**
   * Create an instance of ApolloClient
   * @param options Options required to create ApolloClient
   * @param name client's name
   */
  create(options, name) {
    if (isDefault(name)) {
      this.createDefault(options);
    } else {
      this.createNamed(name, options);
    }
  }
  /**
   * Use a default ApolloClient
   */
  default() {
    return this;
  }
  /**
   * Use a named ApolloClient
   * @param name client's name
   */
  use(name) {
    if (isDefault(name)) {
      return this.default();
    }
    return this.map.get(name);
  }
  /**
   * Create a default ApolloClient, same as `apollo.create(options)`
   * @param options ApolloClient's options
   */
  createDefault(options) {
    if (this.getClient()) {
      throw new Error('Apollo has been already created.');
    }
    return this.setClient(new ApolloClient(options));
  }
  /**
   * Create a named ApolloClient, same as `apollo.create(options, name)`
   * @param name client's name
   * @param options ApolloClient's options
   */
  createNamed(name, options) {
    if (this.map.has(name)) {
      throw new Error(`Client ${name} has been already created`);
    }
    this.map.set(name, new ApolloBase(this._ngZone, this.flags, new ApolloClient(options)));
  }
  /**
   * Remember to clean up the store before removing a client
   * @param name client's name
   */
  removeClient(name) {
    if (isDefault(name)) {
      this._client = undefined;
    } else {
      this.map.delete(name);
    }
  }
  static ɵfac = function Apollo_Factory(t) {
    return new (t || Apollo)(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(APOLLO_OPTIONS, 8), i0.ɵɵinject(APOLLO_NAMED_OPTIONS, 8), i0.ɵɵinject(APOLLO_FLAGS, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: Apollo,
    factory: Apollo.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Apollo, [{
    type: Injectable
  }], () => [{
    type: i0.NgZone
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [APOLLO_OPTIONS]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [APOLLO_NAMED_OPTIONS]
    }, {
      type: Optional
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [APOLLO_FLAGS]
    }, {
      type: Optional
    }]
  }], null);
})();
function isDefault(name) {
  return !name || name === 'default';
}
const PROVIDERS = [Apollo];
class ApolloModule {
  static ɵfac = function ApolloModule_Factory(t) {
    return new (t || ApolloModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ApolloModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: PROVIDERS
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApolloModule, [{
    type: NgModule,
    args: [{
      providers: PROVIDERS
    }]
  }], null, null);
})();
class Query {
  apollo;
  document;
  client = 'default';
  constructor(apollo) {
    this.apollo = apollo;
  }
  watch(variables, options) {
    return this.apollo.use(this.client).watchQuery({
      ...options,
      variables,
      query: this.document
    });
  }
  fetch(variables, options) {
    return this.apollo.use(this.client).query({
      ...options,
      variables,
      query: this.document
    });
  }
  static ɵfac = function Query_Factory(t) {
    return new (t || Query)(i0.ɵɵinject(Apollo));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: Query,
    factory: Query.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Query, [{
    type: Injectable
  }], () => [{
    type: Apollo
  }], null);
})();
class Mutation {
  apollo;
  document;
  client = 'default';
  constructor(apollo) {
    this.apollo = apollo;
  }
  mutate(variables, options) {
    return this.apollo.use(this.client).mutate({
      ...options,
      variables,
      mutation: this.document
    });
  }
  static ɵfac = function Mutation_Factory(t) {
    return new (t || Mutation)(i0.ɵɵinject(Apollo));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: Mutation,
    factory: Mutation.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Mutation, [{
    type: Injectable
  }], () => [{
    type: Apollo
  }], null);
})();
class Subscription {
  apollo;
  document;
  client = 'default';
  constructor(apollo) {
    this.apollo = apollo;
  }
  subscribe(variables, options, extra) {
    return this.apollo.use(this.client).subscribe({
      ...options,
      variables,
      query: this.document
    }, extra);
  }
  static ɵfac = function Subscription_Factory(t) {
    return new (t || Subscription)(i0.ɵɵinject(Apollo));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: Subscription,
    factory: Subscription.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Subscription, [{
    type: Injectable
  }], () => [{
    type: Apollo
  }], null);
})();
function typedGQLTag(literals, ...placeholders) {
  return gql$1(literals, ...placeholders);
}
const gql = typedGQLTag;
const graphql = typedGQLTag;

/**
 * Generated bundle index. Do not edit.
 */

export { APOLLO_FLAGS, APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, Apollo, ApolloBase, ApolloModule, Mutation, Query, QueryRef, Subscription, gql, graphql };
