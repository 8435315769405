import { Injectable, inject } from '@angular/core'
import {
  Auth,
  GoogleAuthProvider,
  User,
  authState,
  signInWithPopup,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from '@angular/fire/auth'

import { map } from 'rxjs'

export interface AuthUser {
  id: string
  name: string | null
  photoUrl: string | null
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth = inject(Auth)

  user$ = authState(this.auth).pipe(
    map((user) => {
      return user ? this.mappingUser(user) : null
    }),
  )

  loginWithGoogle = () => {
    return signInWithPopup(this.auth, new GoogleAuthProvider())
  }

  loginWithEmailAndPassword = (email: string, password: string) => {
    return signInWithEmailAndPassword(this.auth, email, password)
  }

  sendPasswordResetEmail = (email: string) => {
    return sendPasswordResetEmail(this.auth, email)
  }

  logout = () => {
    return signOut(this.auth)
  }

  private mappingUser = (user: User): AuthUser => {
    return {
      id: user.uid,
      name: user.displayName,
      photoUrl: user.photoURL,
    }
  }
}
